<template>
    <div>
        <v-row>
            <v-col>
                <v-row class="sub-title-img-pc hidden-sm-and-down" align="center" justify="center">
                    <v-col
                            class="text-center"
                            cols="12"
                    >
                        <h1 class="text-h4 font-weight-bold white--text">
                            {{pageTitle}}
                        </h1>
                    </v-col>
                </v-row>
                <v-row class="sub-title-img-mo hidden-md-and-up" align="center" justify="center">
                    <v-col
                            class="text-center"
                            cols="12"
                    >
                        <h1 class="text-h4 font-weight-bold white--text">
                            {{pageTitle}}
                        </h1>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-container>
                    <v-row>
                        <v-col cols="12" v-resize="onResize">
                            <ul id="subMenu-ul" style="width:100%; margin:0 auto !important;"
                                class="roundBtn-blue calc-6">
                                <li>
                                    <a id="1" @click="goToPage('1')">공지사항</a>
                                </li>
                                <li>
                                    <a id="3" @click="goToPage('3')">이용후기</a>
                                </li>
                                <li>
                                    <a id="4" @click="goToPage('4')">문의하기</a>
                                </li>
                                <li>
                                    <a id="2" @click="goToPage('2')">포토갤러리</a>
                                </li>
                            </ul>
                        </v-col>
                    </v-row>
                    <v-row class="px-3 my-8">
                        <v-col cols="12">
                            <v-row>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row>
                                <table class="readTable">
                                    <colgroup>
                                        <col style="width:20%"/>
                                        <col style="width:80%"/>
                                    </colgroup>
                                    <tr>
                                        <th class="th">작성자</th>
                                        <td class="td">
                                            {{boardInfo.lstModPrs}}

                                        </td>
                                    </tr>
                                    <tr style="border-bottom:black 1px solid;">
                                        <th class="th">제목</th>
                                        <td class="td">
                                            {{boardInfo.boardTitle}}

                                        </td>
                                    </tr>

                                    <tr style="border-bottom:#cacaca 1px dotted;">
                                        <td class="td py-5 px-0" colspan="2">
                                            <div class="ql-editor">
                                                <span v-html="boardInfo.boardContent"></span>
                                            </div>


                                        </td>
                                    </tr>
                                    <tr style="border-bottom:black 1px solid;">
                                        <td class="td my-5 pa-3" colspan="2" style="text-align: right">
                                            <v-btn
                                                    color=""
                                                    class="mx-4 center-block"
                                                    @click="btnClick(3)"
                                                    style="color:darkred; font-weight: bold;"
                                            >
                                                삭제
                                            </v-btn>
                                            <v-btn
                                                    color=""
                                                    class="mx-4 center-block"
                                                    @click="btnClick(2)"
                                                    style="color:blue; font-weight: bold;"
                                            >
                                                수정
                                            </v-btn>
                                            <v-btn
                                                    color=""
                                                    class="mx-4 center-block"
                                                    @click="btnClick(1)"
                                                    style="color:black; font-weight: bold;"
                                            >
                                                목록
                                            </v-btn>

                                        </td>
                                    </tr>
                                    <tr v-if="isComt" style="border-bottom:black 1px solid; padding-top:20px;">
                                        <td class="td py-5" colspan="2">
                                            <div class="comtTitle">
                                                댓 글
                                            </div>
                                            <v-row class="comtRow2">
                                                <template v-if="!isMobile">
                                                    <v-col>
                                                        <template v-for="(comt, n) in boardInfo.comtList">
                                                            <v-row class="comtRow">
                                                                <v-col cols="2">
                                                                    <template v-if="comt.adminYn=='Y'">
                                                                        <div style="display: inline-flex;">
                                                                           <span class="material-icons"
                                                                                 style="color:#F9A825; margin-right:5px;">
                                                                               stars
                                                                            </span>
                                                                            <span>{{comt.lstModPrs}}</span>
                                                                        </div>

                                                                    </template>
                                                                    <template v-else>
                                                                        <div style="display: inline-flex;">
                                                                           <span class="material-icons"
                                                                                 style="margin-right:5px;">
                                                                               person
                                                                            </span>
                                                                            <span>
                                                                                {{comt.lstModPrs}}
                                                                            </span>
                                                                        </div>

                                                                    </template>

                                                                </v-col>
                                                                <v-col cols="6">
                                                                    <div style="white-space: pre-line">
                                                                        {{comt.comtContent}}
                                                                    </div>
                                                                </v-col>
                                                                <v-col cols="4">
                                                                    <div style="float: right;">
                                                                        <!--<span style="font-size:14px;">{{comt.lstModDate}}</span>-->
                                                                        <div class=""
                                                                             style="float: right; position: relative; margin-left:5px;">
                                                                            <v-btn
                                                                                    color="secondary"
                                                                                    icon
                                                                                    x-small
                                                                                    @click="deleteComt(comt)"
                                                                            >
                                                                                <v-icon>mdi-close</v-icon>
                                                                            </v-btn>
                                                                        </div>
                                                                    </div>
                                                                    <div class="text-center"
                                                                         style="position: relative; float: right;">

                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                        </template>
                                                    </v-col>
                                                </template>
                                                <template v-else>
                                                    <v-col>
                                                        <template v-for="(comt, n) in boardInfo.comtList">
                                                            <v-row class="comtRow">
                                                                <v-col cols="12" style="padding-bottom: 0;">
                                                                    <template v-if="comt.adminYn=='Y'">
                                                                        <div style="display: inline-flex;">
                                                                           <span class="material-icons"
                                                                                 style="color:#F9A825; margin-right:5px;">
                                                                               stars
                                                                            </span>
                                                                            <span>{{comt.lstModPrs}}</span>
                                                                        </div>

                                                                    </template>
                                                                    <template v-else>
                                                                        <div style="display: inline-flex;">
                                                                           <span class="material-icons"
                                                                                 style="margin-right:5px;">
                                                                               person
                                                                            </span>
                                                                            <span>
                                                                                {{comt.lstModPrs}}
                                                                            </span>
                                                                        </div>

                                                                    </template>
                                                                    <div style="float: right;">
                                                                        <!--<span style="font-size:14px;">{{comt.lstModDate}}</span>-->
                                                                        <div class=""
                                                                             style="float: right; position: relative; margin-left:5px;">
                                                                            <v-btn
                                                                                    color="secondary"
                                                                                    icon
                                                                                    x-small
                                                                                    @click="deleteComt(comt)"
                                                                            >
                                                                                <v-icon>mdi-close</v-icon>
                                                                            </v-btn>
                                                                        </div>
                                                                    </div>
                                                                    <div class="text-center"
                                                                         style="position: relative; float: right;">
                                                                    </div>
                                                                </v-col>
                                                                <v-col cols="12">
                                                                    <div style="white-space: pre-line">
                                                                        {{comt.comtContent}}
                                                                    </div>
                                                                </v-col>

                                                            </v-row>
                                                        </template>
                                                    </v-col>
                                                </template>

                                            </v-row>
                                            <v-row class="comtRow">
                                                <v-col cols="12">
                                                        <textarea v-model="comt.comtContent"
                                                                  placeholder="댓글을 입력해 주세요!"
                                                                  style="resize: none; overflow:auto;"
                                                                  wrap="on"
                                                                  id="textarea"
                                                                  @input="input($event.target.value)"></textarea>

                                                </v-col>
                                                <v-col cols="12" class="py-0">
                                                    <div class="comtDiv">
                                                        <template v-if="isAdmin">
                                                            <div class="comtInput">
                                                                <input value="관리자" readonly>
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <div class="comtInput">
                                                                <input id="resvNm" v-model="comt.lstModPrs"
                                                                       placeholder="작성자">
                                                            </div>
                                                            <div class="comtInput">
                                                                <input type="password" id="resvPw"
                                                                       v-model="comt.comtPw"
                                                                       placeholder="암호">
                                                            </div>
                                                        </template>

                                                        <div class="comtBtn">
                                                            <v-btn
                                                                    small
                                                                    color="primary"
                                                                    class="mx-4 white--text center-block comtBtn"
                                                                    @click="insertComt"
                                                                    style="margin: 0 !important; float:right;"
                                                            >
                                                                등록
                                                            </v-btn>
                                                        </div>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            다음글
                                        </th>
                                        <td>
                                            <a class="readTable-a" @click="getNextPre(boardNext)">{{boardNext.boardTitle}}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            이전글
                                        </th>
                                        <td>
                                            <a class="readTable-a"
                                               @click="getNextPre(boardPre)">{{boardPre.boardTitle}}</a>

                                        </td>
                                    </tr>
                                </table>

                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

        <!-- 비밀번호 확인 -->
        <v-row justify="center">
            <v-dialog
                    v-model="dialog"
                    max-width="300px"
            >
                <v-card>
                    <v-card-text>
                        <v-container>
                            <table class="readTable mt-6">
                                <tr style=":hover {color:red;}">
                                    <td class="td" style="padding:0 5px;">
                                        <input type="password" id="boardPw" v-model="boardPw"
                                               style="border: none; height: 37px; margin:0 3px;"
                                               placeholder="비밀번호를 입력해 주세요.">
                                    </td>
                                </tr>
                            </table>
                            <v-spacer></v-spacer>
                            <template v-if="this.$store.state.admin.isLogin && this.isDel">
                                <div>
                                    <input type="checkbox"
                                           v-model="adminDelYn"
                                           class="checkbox-custom-admin-m0">
                                    <label class="label-custom-admin mx-2">관리자 비밀번호로 삭제</label>
                                </div>
                            </template>
                            <template v-if="this.$store.state.admin.isLogin && this.isEdit">
                                <div>
                                    <input type="checkbox"
                                           v-model="adminDelYn"
                                           class="checkbox-custom-admin-m0">
                                    <label class="label-custom-admin mx-2">관리자 비밀번호로 수정</label>
                                </div>
                            </template>
                            <div style="margin-top:15px; text-align: center;">
                                <v-btn
                                        small
                                        color="primary"
                                        class="mx-4 white--text center-block"
                                        @click="dialog = false"
                                >
                                    취소
                                </v-btn>
                                <v-btn
                                        small
                                        color="primary"
                                        class="mx-4 white--text center-block"
                                        @click="confirmBoardPw"
                                >
                                    확인
                                </v-btn>
                            </div>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>

        <div class="text-center">
            <v-dialog
                    v-model="dialog2"
                    persistent
                    width="400"
            >
                <v-sheet
                        :elevation="18"
                        class="mx-auto"
                        height="120"
                        width="400"
                >
                    <div style="font-weight: bold; font-size: 1.5rem; text-align: center; padding:20px;">
                        <span style="color: #B71C1C;">글이 삭제되었습니다.</span>
                    </div>

                    <v-btn
                            color="primary"
                            class="mx-3 white--text center-block tmp-btn"
                            style="float: right; margin-right:40px;"
                            small
                            @click="btnClick(1)"
                    >
                        확인
                    </v-btn>
                </v-sheet>

            </v-dialog>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'Read',

        data: () => ({
            boardInfo: {
                boardGubun: "",
                boardTitle: "",
                boardPw: "",
                boardContent: "",
                regDate: "",
                lstModPrs: "",
                lstModDate: "",
            },
            boardId: "",
            boardGubun: "",
            boardPw: "",
            pageTitle: "",
            dialog: false,
            dialog2: false,
            btnGubun: "0",
            isComt: false,
            isAdmin: false,
            isDel: false,
            isEdit: false,
            comt: {
                comtContent: "",
                comtPw: "",
                lstModPrs: "",
                boardId: "",
            },
            delComt: {
                comtContent: "",
                comtPw: "",
                lstModPrs: "",
                boardId: "",
            },
            boardPreNext: [],
            boardPre: {},
            boardNext: {},
            adminDelYn: false,
            isMobile: false,


        }),
        created: function () {

            // 모바일 체크
            this.isMobile = this.$isMobile();

            window.scrollTo(0, 0);
            // 1: 공지사항 , 2: 포토 , 3: 후기, 4: 고객센터
            this.boardId = this.$getParameterByName('boardId');
            this.boardGubun = this.$getParameterByName('boardGubun');

            //this.isAdmin = this.$store.state.admin.isLogin;

            if (this.$getCookie("AUTH") === 'true') {
                this.isAdmin = true;
            } else if(this.$store.state.admin.loginInfo.homeNm !== undefined){
                this.isAdmin = true;

            } else {

                this.isAdmin = false;
            }

            if (this.boardId === "" || this.boardGubun === "") {
                alert("게시판 정보가 없습니다. 다시 시도해주세요.");
                window.history.back();
            }


            this.fetch();

        },
        mounted() {
        },
        methods: {
            onResize() {

                $('#subMenu-ul').removeClass('calc-4');
                $('#subMenu-ul').removeClass('calc-6');

                if (window.innerWidth < 700) {
                    $('#subMenu-ul').addClass('calc-4');

                } else {
                    $('#subMenu-ul').addClass('calc-6');
                }
            },
            fetch() {
                return this.$store.dispatch("board/getBoardInfo", {boardId: this.boardId, boardGubun: this.boardGubun})
                    .then((resp) => {

                        this.boardInfo = resp.message;
                        this.boardPreNext = resp.preNext;
                        this.setTitle();
                        this.setPreNext();
                        setTimeout(() => {


                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            setTitle() {

                this.$nextTick(function () {
                    //$('.roomSubMenuTable > tr > th').removeClass("active");
                    $('.subMenu-ul > a').removeClass("active");
                    $("#" + this.boardGubun).addClass("active");

                });

                this.boardGubun = this.boardInfo.boardGubun;
                switch (this.boardGubun) {
                    case "1": {
                        this.pageTitle = "공지사항";
                        this.isComt = false;
                        break;
                    }
                    case "2": {
                        this.pageTitle = "포토갤러리";
                        this.isComt = false;
                        break;
                    }
                    case "3": {
                        this.pageTitle = "이용후기";
                        this.isComt = true;
                        break;
                    }
                    case "4": {
                        this.pageTitle = "문의하기";
                        this.isComt = true;
                        break;
                    }

                }
            },
            setPreNext() {
                if (this.boardPreNext.length > 0) {

                    for (let i = 0; this.boardPreNext.length > i; i++) {
                        if (Number(this.boardId) > Number(this.boardPreNext[i].boardId)) {
                            this.boardPre = this.boardPreNext[i];
                        } else {
                            this.boardNext = this.boardPreNext[i];
                        }
                    }
                }
            },
            getNextPre(val) {

                let fdata = {
                    boardId: val.boardId
                };

                return this.$store.dispatch("board/updateHitCnt", fdata)
                    .then((resp) => {
                        setTimeout(() => {

                            location.href = "/community/read" + '?boardId=' + val.boardId + '&boardGubun=' + this.boardGubun;
                        }, 300)
                    })
                    .catch((err) => {
                    })

            },
            btnClick(val) {

                this.boardPw = "";
                this.btnGubun = "0";
                this.adminDelYn = false;
                this.isDel = false;
                this.isEdit = false;

                switch (val) {
                    case 1: { // 목록
                        //window.history.back();
                        this.goToList();
                        break;
                    }
                    case 2: { // 수정

                        this.btnGubun = "2";
                        this.dialog = true;
                        this.isEdit = true;
                        break;
                    }
                    case 3: { // 삭제
                        this.delComt = {};
                        this.btnGubun = "3";
                        this.dialog = true;
                        this.isDel = true;

                        break;
                    }
                    case 4: { // 댓글 삭제
                        this.btnGubun = "4";
                        this.dialog = true;
                        this.isDel = true;
                        break;
                    }
                }
            },
            confirmBoardPw: function () {

                if (this.adminDelYn) {
                    this.confirmAdminPw();
                } else {
                    if (this.boardPw.length === 0) {
                        alert("비밀번호를 입력해 주세요.")
                    } else {

                        if (this.btnGubun === '4') {
                            if (this.boardPw === this.delComt.comtPw) {

                                return this.$store.dispatch("board/deleteComt", this.delComt)
                                    .then((resp) => {
                                        setTimeout(() => {
                                            if (resp.result === 'succeed') {

                                                this.delComt = {
                                                    comtContent: "",
                                                    comtPw: "",
                                                    lstModPrs: "",
                                                    boardId: "",
                                                };

                                                this.dialog = false;

                                                alert(resp.message);
                                                //this.dialog2 = true;

                                                this.fetch()

                                            } else {
                                                alert("제거에 실패 했습니다.")

                                            }

                                        }, 300)
                                    })
                                    .catch((err) => {
                                    });
                            } else {
                                alert("비밀번호를 다시 확인해주세요.")
                            }


                        } else {
                            if (this.boardPw === this.boardInfo.boardPw) {
                                switch (this.btnGubun) {
                                    case "2" : {
                                        //this.$router.push({path: "/community/write"});
                                        location.href = "/community/write" + '?boardId=' + this.boardId + '&boardGubun=' + this.boardGubun;
                                        break;
                                    }
                                    case "3" : {
                                        return this.$store.dispatch("board/deleteBoard", {boardId: this.boardId})
                                            .then((resp) => {
                                                setTimeout(() => {

                                                    //alert(resp.message);
                                                    this.dialog2 = true;

                                                    //this.goToList();
                                                }, 300)
                                            })
                                            .catch((err) => {
                                            });

                                        break;
                                    }
                                    case "4": {
                                        break;
                                    }

                                }
                            } else {
                                alert("비밀번호를 다시 확인해주세요.")
                            }

                        }
                    }
                }


            },
            confirmAdminPw() {

                // 관리자 비밀번호 이용
                if (this.boardPw.length === 0) {
                    alert("비밀번호를 입력해 주세요.")
                } else {

                    // 삭제
                    if (this.isDel) {
                        let formdata = {
                            boardId: this.boardId,
                            usrId: this.$store.state.admin.loginInfo.usrId,
                            usrPw: this.boardPw,
                            isBoard: this.btnGubun
                        };

                        if (this.btnGubun === "4") {
                            formdata['comtId'] = this.delComt.comtId;
                        }

                        return this.$store.dispatch("board/deleteAdmin", formdata)
                            .then((resp) => {
                                setTimeout(() => {

                                    alert(resp.message);

                                    if (this.btnGubun === '3') {
                                        this.goToList();
                                    } else {
                                        this.dialog = false;
                                        this.fetch();
                                    }


                                }, 300)
                            })
                            .catch((err) => {
                            });


                    }

                    // 수정
                    if(this.isEdit){

                        let formdata = {
                            boardId: this.boardId,
                            usrId: this.$store.state.admin.loginInfo.usrId,
                            usrPw: this.boardPw,
                        };

                        return this.$store.dispatch("admin/login", formdata)
                            .then((resp) => {

                                setTimeout(() => {
                                    location.href = "/community/write" + '?boardId=' + this.boardId + '&boardGubun=' + this.boardGubun;
                                }, 300)
                            })
                            .catch((err) => {
                            })



                    }

                }




            },
            goToList() {
                switch (this.boardGubun) {
                    case "1": {
                        this.pageTitle = "공지사항";
                        this.$router.push({path: "/community/notice"});
                        break;
                    }
                    case "2": {
                        this.pageTitle = "포토갤러리";
                        this.$router.push({path: "/community/photo"});
                        break;
                    }
                    case "3": {
                        this.pageTitle = "이용후기";
                        this.$router.push({path: "/community/review"});
                        break;
                    }
                    case "4": {
                        this.pageTitle = "문의하기";
                        this.$router.push({path: "/community/customer"});
                        break;
                    }

                }
            },
            goToPage(val) {
                switch (val) {
                    case "1": {
                        //this.$router.push({path: "/community/notice"});
                        location.href = "/community/notice";
                        break;
                    }
                    case "2": {
                        location.href = "/community/photo";
                        break;
                    }
                    case "3": {
                        location.href = "/community/review";
                        break;
                    }
                    case "4": {
                        location.href = "/community/customer";
                        break;
                    }
                }
            },
            insertComt() {
                this.comt.boardId = this.boardId;

                if (this.isAdmin) {
                    this.comt.lstModPrs = this.$store.state.admin.loginInfo.homeNm;
                    this.comt.adminYn = "Y";
                } else {
                    if (this.comt.lstModPrs.length === 0) {
                        alert("작성자를 입력해 주세요.");
                        return;
                    }

                    if (this.comt.comtPw.length === 0) {
                        alert("비밀번호를 입력해 주세요.");
                        return;
                    }
                }


                if (this.comt.comtContent.length === 0) {
                    alert("내용을 입력해 주세요.");
                    return;
                }

                return this.$store.dispatch("board/insertComt", this.comt)
                    .then((resp) => {
                        setTimeout(() => {

                            if (resp.result === 'succeed') {

                                this.comt = {
                                    comtContent: "",
                                    comtPw: "",
                                    lstModPrs: "",
                                    boardId: "",
                                };

                                this.fetch()

                            } else {
                                alert("등록에 실패 했습니다.")

                            }

                        }, 300)
                    })
                    .catch((err) => {
                    });
            },
            deleteComt(val) {

                if (!this.isAdmin && val.adminYn == 'Y') {

                    alert("권한이 없습니다.");

                    return;

                }


                this.delComt = {};
                this.delComt = val;
                this.btnClick(4);


            },
            input(value) {
                this.$emit('input', value);
                this.resize()
            },
            resize($event) {
                this.$textarea.style.height = '1px'
                this.$textarea.style.height = `${this.$textarea.scrollHeight + 14}px`
            },

        },
        computed: {
            //resvConfirmInfo() { return this.$store.state.reservation.resvConfirmInfo }
            $textarea() {
                return document.getElementById('textarea')
            },
        },

    }
</script>

<style scoped>

</style>

